import React from 'react'
import Html from './html'
import LazyImg from './lazy-img'
import LinkButton from './link-button'
import ImagePlaceholder from './image-placeholder'

export default function TextSection({ title, description, label, url, openInNewWindow, image }) {
  return (
    <section className='u-sectionPadded u-sectionDivider'>
      <h2 className='u-centered u-max-md'>
        {title}
      </h2>
      <Html className='Page-content u-centered u-max-md'>
        {description}
      </Html>
      {(url && label) && (
        <div className='u-centered u-marginBottom--md'>
          <LinkButton
            className=' '
            label={label}
            url={url}
            openInNewWindow={openInNewWindow}
          />
        </div>
      )}
      {image && (
        <div className='u-roundedImg u-max-xlg u-centered'>
          <ImagePlaceholder {...image.mediaDetails}>
            <LazyImg
              src={image.mediaItemUrl}
              alt={image.altText}
            />
          </ImagePlaceholder>
        </div>
      )}
    </section>
  )
}
