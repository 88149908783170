import React, { useState } from 'react'
import YoutubePlayer from './youtube-player'
import LazyImg from './lazy-img'
import Slider from 'react-slick'
import useIsMobile from '../hooks/use-is-mobile'
import Html from './html'

function Wrapper ({ children }) {
  const mobile = useIsMobile()

  if (mobile) {
    return (
      <div className={`Cards`}>
        <Slider
          arrows={false}
          dots
          dotsClass='Carousel-nav'
          autoplay
        >
          {children}
        </Slider>
      </div>
    )
  }

  return (
    <section className={`u-sectionPadded`}>
      <div className={`Cards`}>
        {children}
      </div>
    </section>
  )
}

export default function ImagesAndVideos ({ data }) {
  const {
    posterLeadText,
    posterAdditionalVideos,
    posterDescription
  } = data

  const [videoOpen, setVideoOpen] = useState(false)
  const [youtubeId, setYoutubeId] = useState(null)

  const onOpenVideo = id => {
    setYoutubeId(id)
    setVideoOpen(true)
  }
  const onCloseVideo = () => setVideoOpen(false)

  return (
    <>
      <section className='u-sectionDivider'>
        <div className='u-sectionPadded'>
          <h2 className='u-centered u-max-md'>
            {posterLeadText}
          </h2>
        </div>
        <div className='u-centered'>
          <Wrapper>
            {posterAdditionalVideos.map(item => {
              return (
                <section key={item.description} className={`Card Product-card`}>
                  <div className={`Product Product--secondary Product--tease u-imgHover`}>
                    <button onClick={() => onOpenVideo(item.youtubeId)}>
                      <div className={`Card-image u-mediaContainer`}>
                        <LazyImg src={item.image.sourceUrl} alt={item.image.altText} />
                      </div>
                      <div className='Product-info'>
                        <div>
                          <h3>{item.description}</h3>
                          {item.title && item.youtubeId && (
                            <a
                              className='Button--underline'
                              onClick={() => onOpenVideo(item.youtubeId)}
                            >
                              {item.title}
                            </a>
                          )}
                          {!item.youtubeId && item.title && item.linkUrl && (
                            <a
                              className='Button--underline'
                              href={item.linkUrl}
                            >
                              {item.title}
                            </a>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                </section>
              )
            })}
          </Wrapper>
        </div>
        {posterDescription && (
          <Html className='Page-content u-centered u-max-md'>
            {posterDescription}
          </Html>
        )}
      </section>
      <>
        {posterAdditionalVideos.filter(item => !!item.youtubeId).map(item => {
          return (
            <YoutubePlayer
              key={item.description}
              open={videoOpen && youtubeId === item.youtubeId}
              onClose={onCloseVideo}
              youtubeId={item.youtubeId}
            />
          )
        })}
      </>
    </>
  )
}
