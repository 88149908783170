import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Html from '../components/html'
import Team from '../components/team'
import TeamAnim from '../components/team-anim'

export default function PageAbout ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { title, content, aboutPage } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article className='Page post-type-{{post.post_type}}' id='post-{{post.ID}}'>
        <section className='Primo u-sectionDivider u-sectionPadded'>
          <div className='Primo-inner'>
            <h2>{title}</h2>
            <Html className='Page-content'>
              {content}
            </Html>
          </div>
        </section>
        <section className='u-sectionPadded u-sectionDivider'>
          <Team
            title={aboutPage.aboutPageTeamTitle}
            items={aboutPage.aboutPageTeamTeam}
          />
          <Team
            title={aboutPage.aboutPageInvestorsTitle}
            items={aboutPage.aboutPageInvestorsTeam}
          />
        {/*

      {% if not post.get_field('map_hide_map') and post.get_field('map_title') %}
      <section className='u-sectionDivider'>
          {% include 'map.twig' with {'post': post} %}
      </section>
      {% endif %}
      */}

          <section>
            <TeamAnim />
          </section>
        </section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageAboutQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
      }
    }
  }
`
// export const pageQuery = graphql`
//   query PageAboutQuery($id: ID!) {
//     wpgraphql {
//       page(id: $id) {
//         ...PageFragment
//         ...AboutPageFragment
//       }
//     }
//   }
// `
