import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Html from '../components/html'
import Hero from '../components/hero'
import MediaParade from '../components/media-parade'
import Showcase from '../components/showcase'
import TextSection from '../components/text-section'
import NewsLetter from '../components/newsletter'

export default function PageEbook ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { title, content, hero, mediaParade, textSection, showcase } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article className={`Page`}>
        <Hero data={hero} />
        <section className='u-sectionPadded'>
          <h2 className='u-centered u-noHeader'>{title}</h2>
          {content && (
            <Html className='Page-content'>{content}</Html>
          )}
        </section>
        <section className='u-sectionDivider'>
          <MediaParade data={mediaParade} />
        </section>
        {(textSection && textSection.textSectionTitle) && (
          <TextSection
            title={textSection.textSectionTitle}
            description={textSection.textSectionDescription}
            label={textSection.textSectionCtaButtonLabel}
            url={textSection.textSectionCtaButtonUrl}
            openInNewWindow={textSection.textSectionCtaOpenInNewWindow}
            image={textSection.textSectionImage}
          />
        )}
        {(showcase && showcase.showcaseItems) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <Showcase data={showcase} />
          </section>
        )}
        <NewsLetter />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageEbookQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HeroFragment
        ...MediaParadeFragment
        ...ShowcaseFragment
        ...TextSectionFragment
      }
    }
  }
`
