import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Faq from '../components/faq'
import Facts from '../components/facts'

export default function PageDistributors ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { hero, quickFacts, faq } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero} />
        <Facts data={quickFacts} alignLeft />
        <section className='u-sectionPadded u-sectionDivider'>
          <Faq
            modifier='Faq--center'
            title={faq.faqTitle}
            faqs={faq.faqs}
          />
        </section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageDistributorsQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HeroFragment
        ...QuickFactsFragment
        ...FaqFragment
      }
    }
  }
`
