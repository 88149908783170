import React from 'react'
import Html from './html'
import CollectionCards from './collection-cards'
// import Json from './json'

export default function Collection ({ collection, handle }) {
  const { title, description } = collection

  return (
    <section className={`Shop-section u-sectionDivider`}>
      <section className='Shop-category u-sectionPadded' id={handle}>
        <div className='u-centered u-max-lg'>
          <h2>{title}</h2>
          {description && (
            <div className='Product-description Page-content'>
              <Html>{description}</Html>
            </div>
          )}
        </div>
      </section>
      <CollectionCards collection={collection} />
      {/*
        <Json object={collection} />
      */}
    </section>
  )
}
