import React from 'react'

export default function ImagePlaceholder ({ className, children, width, height }) {
  const paddingBottom = `${height / width * 100}%`

  return (
    <div className={`${className} u-imagePlaceholder`} style={{ paddingBottom }}>
      {children}
    </div>
  )
}
