import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Newsletter from '../components/newsletter'
import Playroom from '../components/playroom'

export default function PagePlayroom ({ data, pageContext, location }) {
  const { page, categories } = data.wpgraphql
  const { hero, playroom } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article className={`Page`}>
        <Hero data={hero} />
        <Playroom
            playroom={playroom}
            categories={categories}
        />
        <Newsletter />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PagePlayroomQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HeroFragment
        ...PlayroomFragment
      }
      ...CategoriesFragment
    }
  }
`
