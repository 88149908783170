import React from 'react'
import Slider from 'react-slick'

export default function MediaParade ({ data }) {
  const {
    mediaParadeLogos,
    mediaParadePeople
  } = data

  return (
    <section className='MediaParade'>
      {mediaParadeLogos && (
        <ul className='MediaParade-logos u-padH'>
          {mediaParadeLogos.map(item => {
            return (
              <li key={item.purple.sourceUrl} className='MediaParade-logo'>
                <img src={item.purple.sourceUrl} alt={item.purple.altText} />
              </li>
            )
          })}
        </ul>
      )}
      {mediaParadePeople && (
        <div className='MediaParade-quotes'>
          <div className='MediaParade-slides'>
            <Slider
              arrows={false}
              dots
              dotsClass='Carousel-nav'
              autoplaySpeed={3000}
              autoplay
            >
              {mediaParadePeople.map(item => {
                return (
                  <div key={item.name} className='MediaParade-quote'>
                    <div className='MediaParade-quoteInner'>
                      <blockquote>
                        <p className='u-quote'>{item.quote}</p>
                      </blockquote>
                      <cite>
                        <h4>{item.name}</h4>
                        <p>{item.role}</p>
                      </cite>
                    </div>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      )}
    </section>
  )
}
