import React, { useState } from 'react'
import LazyImg from './lazy-img'
import YoutubePlayer from './youtube-player'
import LinkButton from './link-button'

function Anchor ({ url }) {
  if (!(url && url.indexOf('#') === 0)) {
    return null
  }
  return (
    <div id={url.slice(1)} />
  )
}

export default function How ({ data }) {
  const {
    howFirstButtonLabel,
    howFirstButtonUrl,
    howSecondButtonLabel,
    howSecondButtonUrl,
    howYoutubeVideoButtonLabel,
    howYoutubeVideoId,
    howImage
  } = data

  const [videoOpen, setVideoOpen] = useState(false)

  const onOpenVideo = () => setVideoOpen(true)

  const onCloseVideo = () => setVideoOpen(false)

  return (
    <>
      <section className='How u-sectionDivider'>
        <div className='How-buttons'>
          {howYoutubeVideoButtonLabel && howYoutubeVideoId && (
            <button className='Button' onClick={onOpenVideo}>
              {howYoutubeVideoButtonLabel}
            </button>
          )}
          {howFirstButtonLabel && howFirstButtonUrl && (
            <LinkButton
              className='Button'
              label={howFirstButtonLabel}
              url={howFirstButtonUrl}
            />
          )}
          {howSecondButtonLabel && howSecondButtonUrl && (
            <LinkButton
              className='Button'
              label={howSecondButtonLabel}
              url={howSecondButtonUrl}
            />
          )}
        </div>
        <article className='How-explanation'>
          <LazyImg
            src={howImage.sourceUrl}
            alt={howImage.altText}
          />
        </article>
        <Anchor url={howFirstButtonUrl} />
        <Anchor url={howSecondButtonUrl} />
      </section>
      {howYoutubeVideoButtonLabel && howYoutubeVideoId && (
        <YoutubePlayer
          open={videoOpen}
          onClose={onCloseVideo}
          youtubeId={howYoutubeVideoId}
        />
      )}
    </>
  )
}
