import React from 'react'

export default function Quotes ({ data }) {
  const {
    quotesTitle,
    quotes
  } = data
  return (
    <section className='u-sectionDivider'>
      <h2 className='u-centered'>
        {quotesTitle}
      </h2>
      <ul className='Quotes'>
        {quotes.map(item => {
          return (
            <li key={item.source} className='Quotes-item'>
              <blockquote className='Quotes-content u-quote'>
                {item.content}
              </blockquote>
              <cite className='Quotes-source'>
                <h4>{item.source}</h4>
              </cite>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
