import React from 'react'
import LazyImg from './lazy-img'
import useOptions from '../hooks/use-options'

import children from './images/shop-notices/children.png'
import secure from './images/shop-notices/secure.png'
import warranty from './images/shop-notices/warranty.png'

export default function ShopNotices () {
  const { store: {
    childImageText,
    lockImageText,
    rosetteImageText
  } } = useOptions()
  if (!(childImageText || lockImageText || rosetteImageText)) {
    return null
  }
  return (
    <section className='Shop-notices'>
      <ul>
        <li>
          <LazyImg src={children} alt='children' />
          <p>{childImageText}</p>
        </li>
        <li>
          <LazyImg src={secure} alt='secure' />
          <p>{lockImageText}</p>
        </li>
        <li>
          <LazyImg src={warranty} alt='warranty' />
          <p>{rosetteImageText}</p>
        </li>
      </ul>
    </section>
  )
}
