import React from 'react'
import { Link } from 'gatsby'

export default function Tabs ({ page, parent, childPages }) {
  return (
    <ul className='Tabs'>
      <li className={`Tabs-item ${page.slug === parent.slug ? 'is-current' : ''}`}>
        <Link className='Tabs-button' to={`/${parent.slug}/`}>
          {parent.title}
        </Link>
	    </li>
      {childPages.edges.map(({ node }) => {
        return (
          <li key={node.slug} className={`Tabs-item ${page.slug === node.slug ? 'is-current' : ''}`}>
            <Link className='Tabs-button' to={`/${parent.slug}/${node.slug}/`}>
              {node.title}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
