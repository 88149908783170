import React from 'react'
import Slider from 'react-slick'
import LazyImg from './lazy-img'
import useIsMobile from '../hooks/use-is-mobile'

function Testimonial ({ item }) {
  return (
    <li className='Testimonials-item'>
      <div className='Testimonials-image'>
        <LazyImg src={item.image.sourceUrl} alt={item.name} />
      </div>
      {item.quote && (
        <blockquote className='Testimonials-quote u-quote'>{item.quote.trim()}</blockquote>
      )}
      <cite className='Testimonials-source'>
        <h4 className='Testimonials-name'>{item.name}</h4>
        <p className='Testimonials-role'>{item.role}</p>
      </cite>
    </li>
  )
}

export default function Testimonials ({ data }) {
  const mobile = useIsMobile()

  const {
    testimonialsTitle,
    people
  } = data

  const items = people.map(item => (
    <Testimonial key={item.name} item={item} />
  ))

  return (
    <section className={`Testimonials Testimonials--${people.length}`}>
      <section className='u-sectionPadded'>
        <h2>
          {testimonialsTitle}
        </h2>
      </section>
      <ul className='Testimonials-people u-padH'>
        {mobile ? (
          <Slider
            arrows={false}
            dots
            dotsClass='Carousel-nav'
            autoplay
          >
            {items}
          </Slider>
        ) : (
          items
        )}
      </ul>
    </section>
  )
}
