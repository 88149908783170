import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
// import Json from '../components/json'
import Html from '../components/html'
import Assets from '../components/assets'
import Quotes from '../components/quotes'
import InTheNews from '../components/in-the-news'
import PressNews from '../components/press-news'
import Faq from '../components/faq'

export default function PagePress ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { title, content, assets, quotes, inTheNews, pressNews, faq } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <section className='Page Page-press u-sectionPadded u-sectionDivider'>
    			<section className='Page-content u-centered'>
    				<h1 className='u-headerMargin'>{title}</h1>
            <Html className='Page-content'>
              {content}
            </Html>
    			</section>
          <Assets data={assets} />
        </section>
        <PressNews data={pressNews} />
        <section className='u-sectionPadded'>
          <section className='u-sectionDivider'>
            <Faq
              modifier='Faq--center'
              title={faq.faqTitle}
              faqs={faq.faqs}
            />
          </section>
          <Quotes data={quotes} />
          <InTheNews data={inTheNews} />
        </section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PagePressQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...AssetsFragment
        ...QuotesFragment
        ...PressNewsFragment
        ...FaqFragment
        ...InTheNewsFragment
      }
    }
  }
`
