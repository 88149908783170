import React, { useState } from 'react'
import Html from './html'
import LazyImg from './lazy-img'
import YoutubePlayer from './youtube-player'
import StoryParticles from './story-particles'
import LinkButton from './link-button'

export default function Welcome ({ title, content, data }) {
  const {
    welcomeVideoButtonLabel,
    welcomeYoutubeVideo,
    welcomeSecondaryButtonLink,
    welcomeSecondaryButtonLabel,
    welcomeImage
  } = data

  const [videoOpen, setVideoOpen] = useState(false)

  const onOpenVideo = () => setVideoOpen(true)

  const onCloseVideo = () => setVideoOpen(false)

  return (
    <>
      <section className='Welcome u-sectionPadded u-centered'>
        <h1 className='Welcome-title'>{title}</h1>
        <Html className='Page-content Welcome-content u-centered u-max-md'>
          {content}
        </Html>
        <div className='Welcome-buttons'>
          <button
            className='Button Button--video'
            onClick={onOpenVideo}
          >
            {welcomeVideoButtonLabel}
          </button>
          {welcomeSecondaryButtonLink && welcomeSecondaryButtonLabel && (
            <LinkButton
              label={welcomeSecondaryButtonLabel}
              url={welcomeSecondaryButtonLink}
              className={'Button'}
            />
          )}
        </div>
        <div className='u-padH u-padH--md'>
          <div className='Welcome-image'>
            <div className='Welcome-wrapper'>
              <LazyImg
                src={welcomeImage.mediaItemUrl}
                alt={welcomeImage.altText}
              />
            </div>
          </div>
        </div>
        <StoryParticles />
      </section>
      <YoutubePlayer
        open={videoOpen}
        onClose={onCloseVideo}
        youtubeId={welcomeYoutubeVideo}
      />
    </>
  )
}
