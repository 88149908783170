import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/title'
import Html from '../components/html'
import Playsets from '../components/playsets'
import How from '../components/how'
import Play from '../components/play'
import TextSection from '../components/text-section'
import TechSpecs from '../components/tech-specs'
import Newsletter from '../components/newsletter'

export default function PageCubetto ({ data, location, pageContext }) {
  const { page, shop } = data.wpgraphql
  const { title, content, howItWorks, play, textSection, techSpecs, products } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <section className='u-sectionPadded'>
          <Title className='u-centered u-headerMargin u-max-lg'>
            {title}
          </Title>
          <Html className='Page-content u-centered u-max-sm-md'>
            {content}
          </Html>
        </section>
        <section className='u-sectionPadded'>
          <How data={howItWorks} />
          <Play data={play} />
        </section>
        {(textSection && textSection.textSectionTitle) && (
          <TextSection
            title={textSection.textSectionTitle}
            description={textSection.textSectionDescription}
            label={textSection.textSectionCtaButtonLabel}
            url={textSection.textSectionCtaButtonUrl}
            openInNewWindow={textSection.textSectionCtaOpenInNewWindow}
            image={textSection.textSectionImage}
          />
        )}
        <section className='u-sectionPadded u-sectionDivider'>
          <TechSpecs data={techSpecs} />
        </section>
        <section className='u-sectionDivider'>
          <Playsets
            title={products.expansionTitle}
            description={products.expansionDescription}
            buyButtonLabel={products.expansionBuyButtonLabel}
            collection={shop.productCollections.shopCollection[0]}
          />
        </section>
      </article>
      <Newsletter />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageCubettoQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HowItWorksFragment
        ...PlayFragment
        ...TextSectionFragment
        ...TechSpecsFragment
        ...ProductsFragment
      }
      ...ShopPageCollectionsFragment
    }
  }
`
