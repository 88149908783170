import React from 'react'
import LazyImg from './lazy-img'
import Html from '../components/html'

export default function Team ({ title, items }) {
  return (
    <section className='Team u-sectionDivider'>
      <h2>{title}</h2>
      <ul className='Team-list'>
        {items.map(item => {
          return (
            <li key={item.name} className='Team-member'>
              <LazyImg
                src={item.image.sourceUrl}
                alt={item.image.altText}
              />
              {item.bio && (
                <Html className='Page-content u-centered'>
                  {item.bio}
                </Html>
              )}
              <h4>{item.name}</h4>
              <p>{item.role}</p>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
