import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import LessonPlans from '../components/lesson-plans'
import Tabs from '../components/tabs'
import TextSection from '../components/text-section'
// import EduForm from '../components/edu-form'
import Playroom from '../components/playroom'
// import Json from '../components/json'

export default function PageEduResources ({ data, location, pageContext }) {
  const { page, categories } = data.wpgraphql
  // const { hero, lessonPlans, textSection2, textSection3, quoteForm, playroom} = page
  const { hero, lessonPlans, textSection2, textSection3, playroom} = page
  const { templateId } = pageContext

  const parent = page.parent || page
  const childPages = parent.childPages

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero}>
          <Tabs
            page={page}
            parent={parent}
            childPages={childPages}
          />
        </Hero>
        {(lessonPlans && lessonPlans.sections) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <LessonPlans data={lessonPlans} />
          </section>
        )}
        {(textSection2 && textSection2.textSection2TextSectionTitle) && (
          <TextSection
            title={textSection2.textSection2TextSectionTitle}
            description={textSection2.textSection2TextSectionDescription}
            label={textSection2.textSection2TextSectionCtaButtonLabel}
            url={textSection2.textSection2TextSectionCtaButtonUrl}
            openInNewWindow={textSection2.textSection2TextSectionCtaOpenInNewWindow}
            image={textSection2.textSection2TextSectionImage}
          />
        )}
        {(playroom && playroom.playroomCategories && playroom.playroomCategories.length) && (
          <Playroom
              playroom={playroom}
              categories={categories}
              initialPostLimit={100}
          />
        )}
        {(textSection3 && textSection3.textSection3TextSectionTitle) && (
          <TextSection
            title={textSection3.textSection3TextSectionTitle}
            description={textSection3.textSection3TextSectionDescription}
            label={textSection3.textSection3TextSectionCtaButtonLabel}
            url={textSection3.textSection3TextSectionCtaButtonUrl}
            openInNewWindow={textSection3.textSection3TextSectionCtaOpenInNewWindow}
            image={textSection3.textSection3TextSectionImage}
          />
        )}
        {/* <EduForm
          title={quoteForm.formTitle}
          description={quoteForm.formDescription}
        /> */}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageEduResourcesQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...ParentChildFragment
        ...HeroFragment
        ...LessonPlansFragment
        ...TextSection2Fragment
        ...PlayroomFragment
        ...TextSection3Fragment
        ...QuoteFormFragment
      }
      ...CategoriesFragment
    }
  }
`
