import React from 'react'

export default function InTheNews ({ data }) {
  const {
    newsTitle,
    newsItems
  } = data
  return (
    <section className='News'>
      <h2 className='u-centered' id='more-news'>
        {newsTitle}
      </h2>
        <ul className='News-items'>
          {newsItems.map(item => {
            return (
              <li key={item.url} className='News-item'>
                <a href={item.url} target='_blank' rel='noopener noreferrer'>
                  <span className='News-date'>{item.date}</span> {item.title}
                </a>
              </li>
            )
          })}
        </ul>
    </section>
  )
}
