import { useEffect, useState } from 'react'
import getScrollRemaining from 'usfl/dom/get-scroll-remaining'

let timeoutId

function hasReachedEnd (offset) {
  if (typeof document === 'undefined') {
    return false
  }
  return getScrollRemaining() < offset
}

export default function useScrolledToEnd (offset = 0) {
  const [scrolledToEnd, setScrolledToEnd] = useState(false)

  useEffect(() => {
    // const onScroll = () => setScrolledToEnd(hasReachedEnd())
    const onScroll = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setScrolledToEnd(hasReachedEnd(offset))
      }, 250)
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('scroll', onScroll)
    }
  }, [setScrolledToEnd, offset])

  return scrolledToEnd
}
