import React from 'react'
import Html from './html'

export default function Facts({ data, alignLeft = false }) {
  const {
    facts,
    ctaButtonLabel,
    ctaButtonUrl,
    ctaOpenInNewWindow
  } = data

  return (
    <section className='Facts-content u-sectionPadded u-sectionDivider'>
      <ul className={`Facts ${alignLeft ? 'Facts--left' : ''}`}>
        {facts.map(item => {
          return (
            <li key={item.title} className='Facts-item'>
              <h3 className='Facts-title'>{item.title}</h3>
              <Html className='Facts-content'>
                {item.content}
              </Html>
            </li>
          )
        })}
      </ul>
      {!!(ctaButtonUrl && ctaButtonLabel) && (
        <div className="u-centered">
          <a className="Button" href={ctaButtonUrl} target={ctaOpenInNewWindow ? '_blank' : '_self'}>
            {ctaButtonLabel}
          </a>
        </div>
      )}
    </section>
  )
}
