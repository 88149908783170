import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/title'
import Html from '../components/html'

export default function PageTerms ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { title, content } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article className={`Page`}>
        <section className='Terms u-sectionPadded'>
          <Title className='h1--sm'>{title}</Title>
          <Html className='Page-content'>
            {content}
          </Html>
        </section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageTermsQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
      }
    }
  }
`
