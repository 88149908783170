import React from 'react'
import stripNonAscii from '../utils/strip-non-ascii'
import Html from './html'
import LazyImg from './lazy-img'

export default function TechSpecs ({ data }) {
  const {
    techTitle,
    techDescription,
    techImage
  } = data

  return (
    <section className='Tech'>
      <h2 id={stripNonAscii(techTitle)}>
        {techTitle}
      </h2>
      <article className='Tech-inner'>
        <Html className='Tech-description Page-content'>
          {techDescription}
        </Html>
        <div className='Tech-image'>
          <LazyImg src={techImage.mediaItemUrl} alt={techImage.altText} />
        </div>
      </article>
    </section>
  )
}
