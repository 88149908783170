import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Welcome from '../components/welcome'
import Play from '../components/play'
import ImagesAndVideos from '../components/images-and-videos'
import Testimonials from '../components/testimonials'
import NewsLetter from '../components/newsletter'

export default function PageHome ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { title, content, welcome, play, imagesAndVideos, testimonials } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Welcome
          title={title}
          content={content}
          data={welcome}
        />
        <section className='u-sectionPadded'>
          <Play data={play} />
        </section>
        <section className='u-sectionDivider'>
          <ImagesAndVideos data={imagesAndVideos} />
        </section>
        <NewsLetter />
        <section className='u-sectionDivider'>
          <Testimonials data={testimonials} />
        </section>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageHomeQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...WelcomeFragment
        ...PlayFragment
        ...ImagesAndVideosFragment
        ...TestimonialsFragment
      }
    }
  }
`
