import React from 'react'
import Html from './html'

export default function Embed ({ title, description, embedCode}) {
  return (
    <article>
      {title && (
        <h2 className={`u-centered`}>
          {title}
        </h2>
      )}
      {description && (
        <Html className='Page-content u-centered u-max-md u-marginBottom'>
          {description}
        </Html>
      )}
      <div className={`u-centered`}>
        <Html>{embedCode}</Html>
      </div>
    </article>
  )
}
