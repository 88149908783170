import React from 'react'
import Html from './html'
import LazyImg from './lazy-img'
import YoutubeEmbed from './youtube-embed'
import Slider from 'react-slick'
import useIsMobile from '../hooks/use-is-mobile'

function Wrapper ({ children }) {
  const mobile = useIsMobile()

  if (mobile) {
    return (
      <div className={`Cards`}>
        <Slider
          arrows={false}
          dots
          dotsClass='Carousel-nav'
          autoplay
        >
          {children}
        </Slider>
      </div>
    )
  }

  return (
    <section className={`u-sectionPadded`}>
      <div className={`Cards`}>
        {children}
      </div>
    </section>
  )
}

function PressNewsLink ({ url, children, className }) {
  return (
    <a
      className={className}
      href={url}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  )
}

function PressNewsItem ({ item }) {
  return (
    <>
      <div className={`Card-image u-mediaContainer`}>
        {item.video && (
          <YoutubeEmbed
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            video={item.video}
          />
        )}
        {!item.video && item.image && (
          <LazyImg
            src={item.image.mediaItemUrl}
            alt={item.image.altText}
          />
        )}
      </div>
      <div className='Product-info'>
        <div style={{ textAlign: 'left' }}>
          <h3>{item.title}</h3>
          {item.description && (
            <Html className='Page-content'>
              {item.description}
            </Html>
          )}
          {item.url && (
            <PressNewsLink url={item.url} className='Button--underline'>
              Read more
            </PressNewsLink>
          )}
        </div>
      </div>
    </>
  )
}

export default function PressNews ({ data }) {
  const {
    pressNewsTitle,
    pressNewsItems
  } = data
  return (
    <section className='u-sectionDivider'>
      {pressNewsTitle && (
        <h2 className='u-centered'>
          {pressNewsTitle}
        </h2>
      )}
      <Wrapper>
        {pressNewsItems.map(item => {
          return (
            <section key={item.title} className={`Card Product-card`}>
              <div className={`Product Product--secondary Product--tease u-imgHover`}>
                {(item.url && !item.video) ? (
                  <PressNewsLink url={item.url}>
                    <PressNewsItem item={item} />
                  </PressNewsLink>
                ) : (
                  <button>
                    <PressNewsItem item={item} />
                  </button>
                )}
              </div>
            </section>
          )
        })}
      </Wrapper>
    </section>
  )
}
