import React, { useState, useEffect, useRef } from 'react'
import Tease from './tease'
import usePosts from '../hooks/use-posts'
import useBreakpoint from '../hooks/use-breakpoint'
import useScrolledToEnd from '../hooks/use-scrolled-to-end'
import Filters from './filters'

const POST_LIMIT = 10

function getFilteredPosts (posts, categories, limit) {
  return posts.filter(node => {
    return node.categories.nodes.some(cat => categories.includes(cat.slug))
  }).slice(0, limit)
}

export default function Playroom ({ playroom, categories, initialPostLimit = POST_LIMIT }) {
  const posts = usePosts()

  const visibleCategories = playroom.playroomCategories.map(({ slug }) => slug)
  const filterCategories = categories.nodes.filter(({ slug }) => visibleCategories.includes(slug))

  const [items, setItems] = useState([])
  const [filters, setFilters] = useState([])
  const [totalHeight, setTotalHeight] = useState(0)
  const [postLimit, setPostLimit] = useState(initialPostLimit)
  const heightMap = useRef({})
  const breakpoint = useBreakpoint()
  const scrolledToEnd = useScrolledToEnd(1000)

  const toggleFilter = slug => {
    setPostLimit(initialPostLimit)
    if (filters.includes(slug)) {
      setFilters(filters.filter(f => f !== slug))
    } else {
      setFilters([...filters, slug])
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.hash = filters.join('&')
    }
  }, [filters])

  useEffect(() => {
    if (typeof window !== 'undefined' && location.hash.length > 1) {
      setFilters(location.hash.slice(1).split('&'))
    }
  }, [])

  const getItemStyle = index => {
    if (breakpoint.size === 'sm') {
      return null
    }
    let left
    if (breakpoint.size === 'md') {
      left = 49.9 * (index % 2)
    } else {
      left = 33.3 * (index % 3)
    }
    return {
      opacity: 1,
      position: 'absolute',
      top: `${tops[index]}px`,
      left: `${left}%`
    }
  }

  const getContainerStyle = totalHeight => {
    if (breakpoint.size === 'sm') {
      return null
    }
    return {
      height: `${totalHeight}px`
    }
  }

  useEffect(() => {
    if (!filters.length) {
      // setItems(posts.slice(0, postLimit))
      setItems(getFilteredPosts(posts, visibleCategories, postLimit))
    } else {
      setItems(getFilteredPosts(posts, filters, postLimit))
    }
  }, [posts, filters, postLimit])

  const onLayout = (id, height) => {
    heightMap.current[id] = height
  }

  const tops = items.map((node, index) => {
    let top = 0
    const cols = breakpoint.size === 'md' ? 2 : 3
    for (let i = index - cols; i > -1; i-=cols) {
      top += heightMap.current[items[i].id] || 0
    }
    return top
  })

  // useLayoutEffect(() => {
  useEffect(() => {
    if (tops.length && tops.length === items.length) {
      let th = 0
      const min = Math.max(tops.length - 4, -1)
      for (let i = tops.length - 1; i > min; i--) {
        const t = tops[i]
        const h = heightMap.current[items[i].id] || 0
        if (t + h > th) {
          th = t + h
        }
      }
      setTotalHeight(th)
    }
  }, [tops, items])

  useEffect(() => {
    if (scrolledToEnd) {
      setPostLimit(limit => limit + initialPostLimit)
    }
  }, [scrolledToEnd])

  return (
    <section className='Resources u-sectionPadded u-marginTop u-sectionDivider'>
        <Filters
            title={playroom.filterTitle}
            categories={filterCategories}
            filters={filters}
            toggleFilter={toggleFilter}
        />
        <section className='Resources-itemsX' style={getContainerStyle(totalHeight)}>
        <ul className='Resources-itemList'>
            {items.map((node, index) => {
                return (
                    <Tease
                        key={node.id}
                        post={node}
                        style={getItemStyle(index)}
                        onLayout={onLayout}
                        breakpointWidth={breakpoint.width}
                    />
                )
            })}
        </ul>
        </section>
    </section>
  )
}
