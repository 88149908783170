import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import { GraphQLClient } from 'graphql-request'
import useSiteMetadata from '../hooks/use-site-metadata'
import PageAbout from '../templates/page-about'
import PageCubetto from '../templates/page-cubetto'
import PageDistributors from '../templates/page-distributors'
import PageEbook from '../templates/page-ebook'
import PageEducation from '../templates/page-education'
import PageEduResources from '../templates/page-edu-resources'
import PageEduCasestudies from '../templates/page-edu-casestudies'
import PageFaq from '../templates/page-faq'
import PageHome from '../templates/page-home'
import PageMaterials from '../templates/page-materials'
import PagePartners from '../templates/page-partners'
import PagePlayroom from '../templates/page-playroom'
import PagePress from '../templates/page-press'
import PageShop from '../templates/page-shop'
import PageSitemap from '../templates/page-sitemap'
import PageStory from '../templates/page-story'
import PageTerms from '../templates/page-terms'
import Page from '../templates/page'
import Post from '../templates/post'
import qs from 'qs'

const previewPostFragment = `
  fragment PreviewPostFragment on Post {
    id
    title
    content
    seo {
      title
      metaDesc
    }
    date
    author {
      name
      avatar {
        url
      }
    }
    blogPostAuthor {
      blogPostAuthorName
      blogPostAuthorImage {
        sourceUrl(size: MEDIUM)
      }
    }
    featuredImage {
      altText
      sourceUrl(size: MEDIUM_LARGE)
    }
    categories(first: 10) {
      nodes {
        slug
      }
    }
  }
`

const postQuery = `
  query PostPreviewQuery($id: ID!) {
    post(id: $id) {
      ...PreviewPostFragment
      revisions {
        nodes {
          ...PreviewPostFragment
        }
      }
    }
  }
  ${previewPostFragment}
`

const previwPageFragment = `
  fragment PreviewPageFragment on Page {
    title
    content
    slug
    pageTemplate
    seo {
      title
      metaDesc
    }
    featuredImage {
      mediaItemUrl
    }
    childPages(first: 50) {
      edges {
        node {
          title
          slug
        }
      }
    }
    #
    parent {
      ... on Page {
        title
        slug
        childPages {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    }
    #
    hero {
      title
      description
      backgroundImage {
        mediaItemUrl
      }
      heroYoutubeVideoButtonLabel
      heroYoutubeVideoId
      linkButtonLabel
      linkButtonUrl
    }
    #
    welcome {
      welcomeImage {
        altText
        mediaItemUrl
      }
      welcomeVideoButtonLabel
      welcomeYoutubeVideo
      welcomeSecondaryButtonLink
      welcomeSecondaryButtonLabel
    }
    #
    quickFacts {
      facts {
        title
        content
      }
      ctaButtonLabel
      ctaButtonUrl
      ctaOpenInNewWindow
    }
    #
    faq {
      faqDisplayAnswers
      faqTitle
      faqDescription
      faqs {
        question
        content
      }
    }
    #
    faq2 {
      faq2Title
      faqs2 {
        question
        content
      }
    }
    #
    faq3 {
      faq3Title
      faqs3 {
        question
        content
      }
    }
    #
    howItWorks {
      howYoutubeVideoButtonLabel
      howYoutubeVideoId
      howImage {
        altText
        sourceUrl(size: LARGE)
      }
    }
    #
    play {
      playTitle
      playDescription
      playLinkLabel
      playLinkUrl
      playItems {
        title
        type
        description
        imageItems {
          image {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
    #
    textSection {
      textSectionTitle
      textSectionDescription
      textSectionCtaButtonLabel
      textSectionCtaButtonUrl
      textSectionCtaOpenInNewWindow
      textSectionImage {
        altText
        mediaItemUrl
        mediaDetails {
          width
          height
        }
      }
    }
    #
    textSection2 {
      textSection2TextSectionTitle
      textSection2TextSectionDescription
      textSection2TextSectionCtaButtonLabel
      textSection2TextSectionCtaButtonUrl
      textSection2TextSectionCtaOpenInNewWindow
      textSection2TextSectionImage {
        altText
        mediaItemUrl
        mediaDetails {
          width
          height
        }
      }
    }
    #
    textSection3 {
      textSection3TextSectionTitle
      textSection3TextSectionDescription
      textSection3TextSectionCtaButtonLabel
      textSection3TextSectionCtaButtonUrl
      textSection3TextSectionCtaOpenInNewWindow
      textSection3TextSectionImage {
        altText
        mediaItemUrl
        mediaDetails {
          width
          height
        }
      }
    }
    #
    resources {
      resourcesFilterLabel
      resourcesDescription
      resourcesItems {
        resourcePage {
          ... on Post {
            id
            title
            excerpt
            slug
            featuredImage {
              sourceUrl(size: MEDIUM_LARGE)
            }
            categories(first: 10) {
              nodes {
                slug
              }
            }
          }
        }
      }
    }
    #
    resources2 {
      resources2FilterLabel
      resources2Description
      resources2Items {
        resourcePage {
          ... on Post {
            id
            title
            excerpt
            slug
            featuredImage {
              sourceUrl(size: MEDIUM_LARGE)
            }
            categories(first: 10) {
              nodes {
                slug
              }
            }
          }
        }
      }
    }
    #
    quoteForm {
      formTitle
      formDescription
    }
    #
    techSpecs {
      techTitle
      techDescription
      techImage {
        altText
        mediaItemUrl
      }
    }
    #
    products {
      expansionTitle
      expansionDescription
      expansionBuyButtonLabel
      expansionBuyButtonUrl
    }
    #
    mediaParade {
      mediaParadeLogos {
        purple {
          altText
          sourceUrl(size: LARGE)
        }
      }
      mediaParadePeople {
        name
        quote
        role
      }
    }
    #
    showcase {
      showcaseTitle
      showcaseItems {
        title
        description
        image {
          altText
          sourceUrl(size: LARGE)
        }
      }
    }
    #
    imagesAndVideos {
      posterLeadText
      posterAdditionalVideos {
        description
        title
        youtubeId
        image {
          sourceUrl(size: LARGE)
          altText
        }
      }
    }
    #
    testimonials {
      testimonialsTitle
      people {
        name
        quote
        role
        image {
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
    }
    #
    story {
      storyTitle
      storyItems {
        date
        description
        video
        image {
          altText
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
    }
    #
    inTheNews {
      newsTitle
      newsItems {
        date
        title
        url
      }
    }
    #
    assets {
      pressRelease
      pressButtons {
        label
        url
        openInNewTab
        file {
          mediaItemUrl
        }
      }
    }
    #
    quotes {
      quotesTitle
      quotes {
        content
        source
      }
    }
    #
    pressNews {
      pressNewsTitle
      pressNewsItems {
        title
        description
        url
        video
        showOnMobile
        image {
          altText
          mediaItemUrl
        }
      }
    }
    #
    playroom {
      filterTitle
      playroomCategories {
        ... on Category {
          slug
        }
      }
    }
    #
    lessonPlans {
      sections {
        title
        description
        items {
          title
          image {
            mediaItemUrl
          }
          url
          label
          file {
            mediaItemUrl
          }
        }
      }
    }
    #
    map {
      mapTitle
      mapEmbedCode
      mapDescription
    }
    #
  }
`

const pageQuery = `
  query PagePreviewQuery($id: ID!) {
    page(id: $id) {
      ...PreviewPageFragment
      #
      revisions {
        nodes {
          ...PreviewPageFragment
        }
      }
      #
    }
    #
    categories(first: 50, where: {hideEmpty: true}) {
      nodes {
        name
        slug
      }
    }
    #
  }
  ${previwPageFragment}
`

const templates = {
  about: PageAbout,
  cubetto: PageCubetto,
  distributors: PageDistributors,
  ebook: PageEbook,
  education: PageEducation,
  'edu-resources': PageEduResources,
  'edu-casestudies': PageEduCasestudies,
  faq: PageFaq,
  home: PageHome,
  materials: PageMaterials,
  partners: PagePartners,
  playroom: PagePlayroom,
  press: PagePress,
  shop: PageShop,
  sitemap: PageSitemap,
  story: PageStory,
  terms: PageTerms,
  page: Page,
  post: Post
}

const previewQuery = `
  query PreviewQuery($id: ID!) {
    node(id: $id) {
      ... on Post {
        id
        title
        postId
      }
      ... on Page {
        id
        title
        pageId
        pageTemplate
      }
    }
  }
`

function getTemplateId (node) {
  const { pageTemplate, pageId } = node
  let templateId = 'post'
  if (!pageTemplate && pageId) {
    templateId = 'playroom'
  } else if (pageTemplate) {
    templateId = pageTemplate.slice('template-'.length, 0 - '.php'.length)
  }
  return templateId
}


function getRevision (status, data) {
  let revision = data
  if (status === 'publish') {
    if (data.page && data.page.revisions.nodes.length) {
      revision = {
        ...data,
        page: data.page.revisions.nodes[0]
      }
    } else if (data.post && data.post.revisions.nodes.length) {
      const { title, content, blogPostAuthor } = data.post.revisions.nodes[0]
      revision = {
        post: {
          ...data.post,
          title,
          content,
          blogPostAuthor
        }
      }
    }
  }
  return revision
}

export default function Preview ({ location, path }) {
  const { wpEndpoint, wpAuth } = useSiteMetadata()
  const [preview, setPreview] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function requestPreview () {
      try {
        const { id, status } = qs.parse(location.search.slice(1))
        const postId = btoa(`post:${id}`)

        console.log('wpEndpoint', wpEndpoint)
        const client = new GraphQLClient(wpEndpoint, {
          headers: {
            'Authorization': wpAuth
          },
          mode: 'cors',
          // credentials: 'include'
        })
        console.log('postId', postId)
        const result = await client.request(previewQuery, { id: postId })
        console.log('result', JSON.stringify(result, null, 2))

        const templateId = getTemplateId(result.node)
        console.log('templateId', templateId)
        const query = templateId === 'post' ? postQuery : pageQuery

        const response = await client.request(query, { id: postId })
        console.log('response', response)
        const data = getRevision(status, response)
        setPreview({ templateId, data })
      } catch (e) {
        setError(e)
      }
    }
    requestPreview()
  }, [])

  if (!preview) {
    return (
      <Layout page={{ seo: { title: 'Loading preview...', noIndex: true }}} template='terms'>
        <section className='u-sectionPadded'>
          {error ? (
            <>
              <h3>Error</h3>
              <p>{error.message}</p>
            </>
          ) : (
            <h3>Loading preview...</h3>
          )}
        </section>
      </Layout>
    )
  }

  const { templateId, data } = preview
  const Component = templates[templateId]

  return (
    <Component
      data={{ wpgraphql: data }}
      pageContext={{ templateId }}
      location={location}
      path={path}
    />
  )
}
