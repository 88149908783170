import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import LessonPlans from '../components/lesson-plans'
import Embed from '../components/embed'
// import Json from '../components/json'

export default function PagePartners ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { hero, lessonPlans, map } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero} />
        {(map && map.mapEmbedCode) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <Embed
              title={map.mapTitle}
              description={map.mapDescription}
              embedCode={map.mapEmbedCode}
            />
          </section>
        )}
        {(lessonPlans && lessonPlans.sections) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <LessonPlans data={lessonPlans} />
          </section>
        )}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PagePartnersQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...HeroFragment
        ...LessonPlansFragment
        ...MapFragment
      }
    }
  }
`
