import React, { useState } from 'react'
import Collection from './collection'
import ShopNotices from './shop-notices'
import NewsLetter from './newsletter'
import Html from './html'
import Filters from './filters'
import slugify from '../utils/slugify'

export default function Shop ({ page }) {
  const  { title, content, productCollections } = page

  const [filters, setFilters] = useState([])

  const categories = productCollections.shopCollection.map(item => ({
    name: item.menuLabel,
    slug: slugify(item.menuLabel)
  }))

  const toggleFilter = slug => {
    if (filters.includes(slug)) {
      setFilters([])
    } else {
      setFilters([slug])
    }
  }

  let items = productCollections.shopCollection
  if (filters.length) {
    items = productCollections.shopCollection.filter(item => filters.includes(slugify(item.menuLabel)))
  }

  return (
    <article>
      <section className='Shop u-headerMargin'>
        <section className='u-sectionPadded u-sectionDivider'>
          <h1
            className='u-centered u-max-lg'
            style={{ marginBottom: '3vw' }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Html className='u-centered'>{content}</Html>
          <Filters
            categories={categories}
            filters={filters}
            toggleFilter={toggleFilter}
          />
        </section>
        {items.map(collection => {
          return (
            <Collection
              key={collection.title}
              collection={collection}
              handle={slugify(collection.menuLabel)}
            />
          )
        })}
      </section>
      <section className='u-sectionPadded u-sectionDivider'>
        <ShopNotices />
      </section>
      <NewsLetter />
    </article>
  )
}
