import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Title from '../components/title'
import Html from '../components/html'
import Faq from '../components/faq'
import NewsLetter from '../components/newsletter'

export default function PageFaq ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { title, content, faq, faq2, faq3 } = page
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <section className='u-sectionPadded u-sectionDivider'>
          <section className='Page-content u-centered u-sectionDivider'>
            <Title className='h1--sm u-headerMargin'>{title}</Title>
            <Html>{content}</Html>
          </section>
          <section className='u-sectionDivider'>
            <Faq
              modifier='Faq--center'
              title={faq.faqTitle}
              faqs={faq.faqs}
            />
          </section>
          <section className='u-sectionDivider'>
            <Faq
              modifier='Faq--center'
              title={faq2.faq2Title}
              faqs={faq2.faqs2}
            />
          </section>
          <section className='u-sectionDivider'>
            <Faq
              modifier='Faq--center'
              title={faq3.faq3Title}
              faqs={faq3.faqs3}
            />
          </section>
        </section>
        <NewsLetter />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageFaqQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...FaqFragment
        ...Faq2Fragment
        ...Faq3Fragment
      }
    }
  }
`
