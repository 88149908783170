import React from 'react'
import Html from './html'

function FaqItem ({ faq }) {
  return (
    <li className={`Faq-item`}>
      <h4 className={`Faq-title`}>
        {faq.question}
      </h4>
      <Html className={`Faq-content Page-content`}>
        {faq.content}
      </Html>
    </li>
  )
}

export default function Faq ({ title, description, faqs, modifier, displayAnswers = true }) {
  const halfway = Math.round(faqs.length / 2)
  const faqsA = faqs.slice(0, halfway)
  const faqsB = faqs.slice(halfway + 1)
  return (
    <section className={`Faq ${displayAnswers ? 'Faq--display' : 'Faq--hide'} ${modifier}`}>
      {title && (
        <h2 className={`u-centered`}>
          {title}
        </h2>
      )}
      {description && (
        <Html className='Page-content u-centered u-max-sm u-marginBottom'>
          {description}
        </Html>
      )}
      <div className={`Faq-columns`}>
        <ul className={`Faq-list`}>
          {faqsA.map(faq => (
            <FaqItem key={faq.question} faq={faq} />
          ))}
        </ul>
        <ul className={`Faq-list`}>
          {faqsB.map(faq => (
            <FaqItem key={faq.question} faq={faq} />
          ))}
        </ul>
      </div>
    </section>
  )
}
