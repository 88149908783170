import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tabs from '../components/tabs'
import TextSection from '../components/text-section'
import Resources from '../components/resources'
// import EduForm from '../components/edu-form'
// import Json from '../components/json'

export default function PageEduCasestudies ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  // const { hero, resources, resources2, textSection, quoteForm } = page
  const { hero, resources, resources2, textSection } = page
  const { templateId } = pageContext

  const parent = page.parent || page
  const childPages = parent.childPages

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero}>
          <Tabs
            page={page}
            parent={parent}
            childPages={childPages}
          />
        </Hero>
        {(resources && resources.resourcesItems) && (
          <Resources
            title={resources.resourcesFilterLabel}
            description={resources.resourcesDescription}
            items={resources.resourcesItems}
          />
        )}
        {(resources2 && resources2.resources2Items) && (
          <Resources
            title={resources2.resources2FilterLabel}
            description={resources2.resources2Description}
            items={resources2.resources2Items}
          />
        )}
        {(textSection && textSection.textSectionTitle) && (
          <TextSection
            title={textSection.textSectionTitle}
            description={textSection.textSectionDescription}
            label={textSection.textSectionCtaButtonLabel}
            url={textSection.textSectionCtaButtonUrl}
            openInNewWindow={textSection.textSectionCtaOpenInNewWindow}
            image={textSection.textSectionImage}
          />
        )}
        {/* <EduForm
          title={quoteForm.formTitle}
          description={quoteForm.formDescription}
        /> */}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageEduCasestudiesQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...ParentChildFragment
        ...HeroFragment
        ...TextSectionFragment
        ...ResourcesFragment
        ...Resources2Fragment
        ...QuoteFormFragment
      }
    }
  }
`
