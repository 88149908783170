import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Shop from '../components/shop'

export default function PageShop ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  const { templateId } = pageContext

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <Shop page={page} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageShopQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...ShopCollectionsFragment
      }
    }
  }
`
