import React from 'react'
import Html from './html'
import Tease from '../components/tease'

export default function Resources({ title, description, items }) {
  return (
    <section className='u-sectionPadded u-sectionDivider'>
      <h2 className='u-centered u-max-md'>
        {title}
      </h2>
      <Html className='Page-content u-centered u-max-md'>
        {description}
      </Html>
      <section className='Resources-items Resources-items--fixed'>
        <ul className='Resources-itemList Resources-itemList--fixed'>
          {items.map(({ resourcePage: node }, index) => {
            return (
              <Tease
                key={node.id}
                post={node}
              />
            )
          })}
        </ul>
      </section>
    </section>
  )
}
