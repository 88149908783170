import React from 'react'
import Slider from 'react-slick'
import LazyImg from './lazy-img'
import useIsMobile from '../hooks/use-is-mobile'

export default function Showcase({ data }) {
  const {
    showcaseTitle,
    showcaseItems
  } = data

  const mobile = useIsMobile()

  const items = showcaseItems.map(item => {
    return (
      <li key={item.title} className='Showcase-item'>
        <div className='Showcase-content'>
          <LazyImg
            src={item.image.sourceUrl}
            alt={item.image.altText}
          />
          <div className='Showcase-description Page-content'>
            {item.description}
          </div>
          <h4 className='Showcase-title'>{item.title}</h4>
        </div>
      </li>
    )
  })

  return (
    <section className='Showcase'>
      <h2 className='u-centered u-max-lg'>
        {showcaseTitle}
      </h2>
      <div className='Showcase-inner'>
          {mobile ? (
            <Slider
              arrows={false}
              dots
              dotsClass='Carousel-nav'
              autoplay
            >
              {items}
            </Slider>
          ) : (
            <ul className='Showcase-items'>
              {items}
            </ul>
          )}
      </div>
    </section>
  )
}
