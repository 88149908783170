import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Tabs from '../components/tabs'
import Showcase from '../components/showcase'
import ImagesAndVideos from '../components/images-and-videos'
import TextSection from '../components/text-section'
// import EduForm from '../components/edu-form'
import Faq from '../components/faq'
// import Json from '../components/json'

export default function PageEducation ({ data, location, pageContext }) {
  const { page } = data.wpgraphql
  // const { hero, showcase, imagesAndVideos, textSection, faq, quoteForm } = page
  const { hero, showcase, imagesAndVideos, textSection, faq } = page
  const { templateId } = pageContext

  const parent = page.parent || page
  const childPages = parent.childPages

  return (
    <Layout page={page} template={templateId} path={location.pathname}>
      <article>
        <Hero data={hero}>
          <Tabs
            page={page}
            parent={parent}
            childPages={childPages}
          />
        </Hero>
        {(showcase && showcase.showcaseItems) && (
          <section className='u-sectionPadded u-sectionDivider'>
            <Showcase data={showcase} />
          </section>
        )}
        {(imagesAndVideos && imagesAndVideos.posterAdditionalVideos) && (
          <section className='u-sectionDivider'>
            <ImagesAndVideos data={imagesAndVideos} />
          </section>
        )}
        {(textSection && textSection.textSectionTitle) && (
          <TextSection
            title={textSection.textSectionTitle}
            description={textSection.textSectionDescription}
            label={textSection.textSectionCtaButtonLabel}
            url={textSection.textSectionCtaButtonUrl}
            openInNewWindow={textSection.textSectionCtaOpenInNewWindow}
            image={textSection.textSectionImage}
          />
        )}
        {(faq && faq.faqTitle) && (
          <section className='u-sectionPadded'>
            <Faq
              modifier='Faq--center'
              title={faq.faqTitle}
              description={faq.faqDescription}
              faqs={faq.faqs}
            />
          </section>
        )}
        {/* <EduForm
          title={quoteForm.formTitle}
          description={quoteForm.formDescription}
        /> */}
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageEducationQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        ...PageFragment
        ...ParentChildFragment
        ...HeroFragment
        ...ShowcaseFragment
        ...ImagesAndVideosFragment
        ...TextSectionFragment
        ...FaqFragment
        ...QuoteFormFragment
      }
    }
  }
`
