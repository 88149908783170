import React from 'react'
import Html from '../components/html'

const classNames = [
  'Button Button--outline Button--grape Button--camera',
  'Button Button--document'
]

export default function Assets ({ data }) {
  const {
    pressRelease,
    pressButtons
  } = data
  return (
    <article className='Assets'>
        <section className='Assets-media u-sectionDivider'>
          {pressButtons.map((item, index) => {
            if (item.file) {
              return (
                <a
                  key={item.label}
                  className={classNames[index]}
                  href={item.file.mediaItemUrl}
                  download={item.file.name}
                >
                  {item.label}
                </a>
              )
            }
            return (
              <a
                key={item.label}
                className={classNames[index]}
                href={item.url}
                target={item.openInNewTab ? '_blank' : undefined}
                rel={item.openInNewTab ? 'noopener noreferrer' : undefined}
              >
                {item.label}
              </a>
            )
          })}
        </section>

        <Html className='Assets-pressRelease Page-content'>
          {pressRelease}
        </Html>
    </article>
  )
}
