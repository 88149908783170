import { useState, useEffect } from 'react'

let timeoutId

function getBreakpoint () {
  let size = 'lg'
  let width = 1000

  if (typeof window !== 'undefined') {
    width = window.innerWidth
    if (width < 640) {
      size = 'sm'
    } else if (width < 900) {
      size = 'md'
    }
  }

  return { size, width }
}

export default function useBreakpoint () {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint())

  useEffect(() => {
    const onResize = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setBreakpoint(getBreakpoint())
      }, 250)
    }

    window.addEventListener('resize', onResize)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return breakpoint
}
